import Toast from 'bootstrap/js/dist/toast';
import { dayjs } from '../../components/dayjs-localized';
import Cookies from 'js-cookie';

const NotificationManager = {
  storageNotificationSubscribeClosedKey: 'subscribeNotificationClosedDate',
  refreshPage: false,
  cookieKey: 'pushNotificationSubscriptionUpdate',
  get subscribed() {
    let subscribed = true;
    if (!('Notification' in window) || Notification.permission === 'default') {
      subscribed = false;
    }
    return subscribed;
  },
  get notificationSubscribeClosedDate() {
    return localStorage.getItem(this.storageNotificationSubscribeClosedKey);
  },
  /**
   * @param date {string}
   */
  set notificationSubscribeClosedDate(date) {
    localStorage.setItem(this.storageNotificationSubscribeClosedKey, date);
  },
  init() {
    this.showIncompatibleMessage();
    if (navigator.serviceWorker) {
      // we add an interval to wait for the window.serviceWorkerRegistration to be defined before doing anything else
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;

        // in case the setInterval loops on itself and does not stop, we stop it after 15secs
        if (elapsedTime > 15000) {
          clearInterval(interval);
        }

        if (window.serviceWorkerRegistration !== undefined) {
          clearInterval(interval);
          this.showNotificationSubscription();
          this.updateSubscription();
        }
      }, 200);
    }
  },
  requestPermission() {
    const that = this;
    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification');

      // Let's check whether notification permissions have already been granted
    } else if (Notification.permission === 'granted') {
      console.log('Permission to receive notifications has been granted');
      that.registerUserWebPush();

      // Otherwise, we need to ask the user for permission
    } else if (Notification.permission !== 'denied') {
      Notification
        .requestPermission()
        .then(
          (permission) => {
          // If the user accepts, let's create a notification
            if (permission === 'granted') {
              that.registerUserWebPush();
            }
          }
        );
    }
  },
  /**
   * serviceWorkerRegistration is initialized in the serviceworker companion and is accessible through window
   */
  registerUserWebPush() {
    const that = this;
    window.serviceWorkerRegistration
      .pushManager
      .getSubscription()
      .then(
        (subscription) => {
          if (subscription) {
            return subscription;
          }

          return window.serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: window.vapidPublicKey,
          });
        }
      ).then(
        function(subscription) {
          $.post('/push_notification_subscriptions', {
            subscription: subscription.toJSON(),
            refreshPage: that.refreshPage,
          });
        }
      );
  },
  showNotificationSubscription() {
    if (this.subscribed) {
      return false;
    }

    const that = this;
    let toast = null;

    $('.push-notification-not-subscribed').show();

    if (!that.notificationSubscribeClosedDate || dayjs(that.notificationSubscribeClosedDate).add(12, 'M').isBefore(dayjs())) {
      // We use the toast module of bootstrap to show the subscription to the notification globally
      const toastElement = document.getElementById('notification-subscribe');
      toast = new Toast(toastElement, { autohide: false });
      toast.show();

      toastElement.addEventListener('hidden.bs.toast', () => {
        that.notificationSubscribeClosedDate = dayjs().format();
      });
    }

    // Get subscription through a click
    $('.notification-subscribe-btn').click((e) => {
      if (e.target.dataset.refresh === 'true') {
        that.refreshPage = true;
      }
      that.requestPermission();
      $('.push-notification-not-subscribed').hide();
      if (toast !== null) {
        toast.hide();
      }
    });
  },
  showIncompatibleMessage() {
    if (!('Notification' in window)) {
      $('.push-notification-incompatible').show();
    }
  },
  /**
   * We create a cookie that expires in 48h so that we can ensure that the information
   * of the subscription stays up to date every two days
   */
  updateSubscription() {
    if (!Cookies.get(this.cookieKey)) {
      Cookies.set(this.cookieKey, 'true', { expires: 2 });
      window.serviceWorkerRegistration
        .pushManager
        .getSubscription()
        .then((subscription) => {
          if (subscription) {
            $.ajax({
              type: 'PATCH',
              url: '/push_notification_subscriptions/update_subscription',
              data: {
                subscription: subscription.toJSON(),
              },
            });
          }
        });
    }
  },
};

document.addEventListener('turbo:load', function() {
  NotificationManager.init();
});
